$fancybox-breakpoints: (
  xl: (
    bp-width: 1024px,
    container: (
      --carousel-button-width: 48px,
      --carousel-button-height: 48px,
      --carousel-button-svg-width: 27px,
      --carousel-button-svg-height: 27px,
    ),
    button-close-outside: (
      right: 40px,
    ),
    button-next: (
      right: 40px,
    ),
    button-prev: (
      left: 40px,
    ),
    slide: (
      padding: 64px 100px,
    ),
    toolbar: (
      padding: 8px,
    ),
  ),
) !default;

$fancybox-container-color: var(--fancybox-color, #fff) !default;
$fancybox-container-padding: env(safe-area-inset-top, 0px) env(safe-area-inset-right, 0px)
  env(safe-area-inset-bottom, 0px) env(safe-area-inset-left, 0px) !default;

$fancybox-backdrop-bg: var(--fancybox-bg, rgba(83, 83, 83, 0.9)) !default;

$fancybox-accent-color: var(--fancybox-accent-color, rgba(255, 255, 255, 0.94)) !default;

$fancybox-spinner-color: var(--fancybox-color, currentColor) !default;
$fancybox-spinner-width: 50px !default;
$fancybox-spinner-height: 50px !default;
$fancybox-spinner-svg-stroke-width: 2.75 !default;

$fancybox-slide-padding: 48px 8px 8px 8px !default;

$fancybox-content-color: var(--fancybox-content-color, #374151) !default;
$fancybox-content-bg: var(--fancybox-content-bg, #fff) !default;
$fancybox-content-padding: 36px !default;

$fancybox-caption-margin: 0 !default;
$fancybox-caption-padding: 1rem 0 0 0 !default;
$fancybox-caption-color: var(--fancybox-color, currentColor) !default;
$fancybox-caption-line-height: 1.375 !default;

/*
  Thumbs plugin
*/

$fancybox-thumbs-padding: 0px 3px !default;
$fancybox-thumbs-spacing: 8px 3px !default;
$fancybox-thumbs-radius: 0px 0px !default;
$fancybox-thumbs-width: var(--fancybox-thumbs-width, 60px) !default;
$fancybox-thumbs-ratio: var(--fancybox-thumbs-ratio, 1) !default; /* 3/2 */
$fancybox-thumbs-border-radius: var(--fancybox-thumbs-border-radius, 0px) !default;
$fancybox-thumbs-bg: rgba(0, 0, 0, 0.5) !default;

$fancybox-thumbs-selected-opacity: false !default;
$fancybox-thumbs-selected-border: true !default;

/*
  HTML plugin
*/
$fancybox-iframe-width: 100% !default;
$fancybox-iframe-height: 80% !default;

$fancybox-video-width: 960px !default;
$fancybox-video-height: 540px !default;

/*
  Toolbar plugin
*/

$fancybox-toolbar-bg: linear-gradient(
  to top,
  hsla(0, 0%, 0%, 0) 0%,
  hsla(0, 0%, 0%, 0.006) 8.1%,
  hsla(0, 0%, 0%, 0.021) 15.5%,
  hsla(0, 0%, 0%, 0.046) 22.5%,
  hsla(0, 0%, 0%, 0.077) 29%,
  hsla(0, 0%, 0%, 0.114) 35.3%,
  hsla(0, 0%, 0%, 0.155) 41.2%,
  hsla(0, 0%, 0%, 0.198) 47.1%,
  hsla(0, 0%, 0%, 0.242) 52.9%,
  hsla(0, 0%, 0%, 0.285) 58.8%,
  hsla(0, 0%, 0%, 0.326) 64.7%,
  hsla(0, 0%, 0%, 0.363) 71%,
  hsla(0, 0%, 0%, 0.394) 77.5%,
  hsla(0, 0%, 0%, 0.419) 84.5%,
  hsla(0, 0%, 0%, 0.434) 91.9%,
  hsla(0, 0%, 0%, 0.44) 100%
) !default;
$fancybox-toolbar-padding: 0 !default;
