.not-selectable {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.#{$carousel-prefix}carousel {
  position: relative;
  box-sizing: border-box;

  & *,
  & *:before,
  & *:after {
    box-sizing: inherit;
  }

  &.is-draggable {
    cursor: move;
    cursor: grab;
  }

  &.is-dragging {
    cursor: move;
    cursor: grabbing;
  }
}

.#{$carousel-prefix}carousel__viewport {
  position: relative;
  overflow: hidden;

  max-width: 100%;
  max-height: 100%;
}

.#{$carousel-prefix}carousel__track {
  display: flex;
}

.#{$carousel-prefix}carousel__slide {
  flex: 0 0 auto;
  width: $carousel-slide-width;
  max-width: 100%;
  padding: 1rem;
  position: relative;

  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;

  /* https://github.com/andreszs/jquery.events.swipe#pointer-event-remarks */
  touch-action: pan-y;
}
