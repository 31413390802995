.fancybox__carousel .carousel__slide {
  scrollbar-width: thin;
  scrollbar-color: #ccc rgba(255, 255, 255, 0.1);
}

.fancybox__carousel .carousel__slide::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.fancybox__carousel .carousel__slide::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
}

.fancybox__carousel .carousel__slide::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 2px;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
}
