.fancybox__carousel {
  &.is-draggable .fancybox__slide,
  &.is-draggable .fancybox__slide .fancybox__content {
    cursor: move;
    cursor: grab;
  }

  &.is-dragging .fancybox__slide,
  &.is-dragging .fancybox__slide .fancybox__content {
    cursor: move;
    cursor: grabbing;
  }

  & .fancybox__slide .fancybox__content {
    cursor: auto;
  }

  & .fancybox__slide.can-zoom_in .fancybox__content {
    cursor: zoom-in;
  }

  & .fancybox__slide.can-zoom_out .fancybox__content {
    cursor: zoom-out;
  }

  & .fancybox__slide.is-draggable .fancybox__content {
    cursor: move;
    cursor: grab;
  }

  & .fancybox__slide.is-dragging .fancybox__content {
    cursor: move;
    cursor: grabbing;
  }
}
