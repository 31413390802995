#l1Nav {
  margin: 0;
  padding: 0;
  overflow: hidden;
  color: $tertiary-color;
  float: left;
  position: absolute;
  top: 52px;
  left: 205px;
  width: 700px;
  font-size: 12px;
  @include smView() {
    font-size: 16px;
  }
  ul{
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    li{
      float: left;
      display: block;
      list-style-type: none;
      position: relative;
      margin: 0;
      padding: 0;
      background: no-repeat 10px 4px url("../img/base/navigation_arrow.gif") transparent;
      a{
        color: $tertiary-color;
        margin: 0 50px 0 20px;
        text-transform: uppercase;
        &:hover{
          color: $secondary-color;
        }
      }
      &.active, &.open{
        a{
          color: $secondary-color;
        }
      }
    }
  }
}

#l2Nav{
  position: relative;
  max-width: 180px;
  margin-right: 35px;
  flex: 0 180px;
  font-size: 12px;
  @include smView() {
    font-size: 16px;
  }
  @include smView() {
    display: none;
  }
  ul.nav{
    position: relative;
    width: auto;
    display: block;
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative;
    border-bottom: 1px solid $quartiary-color;
    >li{
      &:hover{
        >.gallery-preview{
          display: flex;
        }
      }
    }
    li{
      position: relative;
      display: block;
      list-style-type: none;
      margin: 0;
      padding: 0;
      position: relative;
      border-top: 1px solid $quartiary-color;

      .gallery-preview{
        border: 1px solid #fff;
        background-color: rgba(68,65,65,0.8);
        padding: 6px 20px 3px 25px;
        position: absolute;
        top: 0px;
        left: 178px;
        z-index: 500;
        display: none;
        flex-wrap: nowrap;

        a{
          color: $tertiary-color;
          display: inline;
          font-weight: normal;
          text-transform: uppercase;
          margin: 0 0 0 0px;
          padding: 0 0 0 0;
          background: none;
        }
        img{
          display: inline-block;
          margin-right: 5px;
          height: 30px;
          &:hover{
            background-color: white;
             opacity: 0.8;
            cursor: pointer;
            cursor: hand;
          }
        }
      }
      ul{
        li{
          .gallery-preview{
            left: 135px;
          }
        }
      }
      &.open, &.active{
        > a{
          color: $secondary-color;
        }
        ul{
          li{
            border-color: $quintiary-color;
            &.active{
              a{
                color: $secondary-color;
              }
            }
            .gallery-preview{
              a{
                color: $tertiary-color;
                display: inline;
                font-weight: normal;
                text-transform: uppercase;
                margin: 0 0 0 0px;
                padding: 0 0 0 0;
                background: none;
              }
            }
            a{
              display: block;
              padding: 3px 0 3px 6px;
              background: url('../img/base/subnavigation_arrow.gif') transparent no-repeat 0px 7px;
              margin: 0;
              color: $tertiary-color;
              &:hover{
                color: $secondary-color;
              }
            }
          }
        }
      }
      a{
        color: $tertiary-color;
        display: block;
        font-weight: normal;
        text-transform: uppercase;
        margin: 0 0 0 20px;
        padding: 3px 0 3px 17px;
        background: url('../img/base/navigation_arrow.gif') transparent no-repeat 6px 7px;
        &:hover{
          color: $secondary-color;
        }
      }
      ul{
        padding: 0 0 0 45px;
        margin: 5px 0 10px 0;
        border-bottom: none;
      }
    }
  }

}

#meta-navigation{
  height: 29px;
  float: right;
  position: absolute;
  right: 20px;
  top: 10px;
  z-index: 15;
  font-size: 12px;
  @include smView() {
    font-size: 16px;
  }
  ul{
    list-style: none;
    margin: 0;
    padding: 0;
    li{
      float: left;
      margin: 0 15px 0 0;
      padding: 0;
      &.active{
        a{
         color: $tertiary-color;
        }
      }
      a{
        text-transform: uppercase;
        padding-left: 18px;
        text-decoration: none;
        background: no-repeat 10px 4px url("../img/base/navigation_arrow.gif") transparent;
        color: $secondary-color;
        @include smView() {
          border-bottom: 1px solid $secondary-color;
        }
        &:hover{
          color: $tertiary-color;
        }
      }
    }
  }
}


#global-nav{
  @include smView() {
    display: none;
    padding-top: 0px;
    top: 50px;
    position: absolute;
    width:100vw;
    height: calc(100vh - 50px);
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: $quartiary-color;
    z-index: 999;
    &.open{
      display: block;
    }
  }
  #meta-navigation{
    @include smView() {
      position: relative;
      float: none;
      padding: 0 15px;
      right: auto;
      height: auto;
      top:0;
      ul{
        display: block;
        text-align: right;
        li{
          float: none;
          display: block;
          margin: 0;
          padding: 0;
          li.active{
            >a{
              color: $light-color;
            }
          }
          a{
            color: $primary-color;
            line-height: 20px;
            font-size: 18px;
            padding: 10px 0;
            display: block;
            background: none;
          }
        }
      }
    }
  }
  #mobile-navigation{
    display: none;
    position: relative;
    padding: 0 15px;
    @include smView() {
      display: block;
    }

    a{
      min-height: 40px;
      color: $primary-color;

      text-align: right;
      display: flex;
      align-items: center;
      width: 100%;
      flex-direction: row-reverse;
      border-bottom: 1px solid $secondary-color;
      &.btn-1{
         span{
           &.link{
             line-height: 20px;
             font-size: 22px;
           }
         }
      }
      span{
        text-align: right;
        &.gallery-preview{
          flex-grow: 2;
          text-align: left;
          img{
            display: inline-block;
            margin-top: 5px;

          }
        }
        &.link{
          line-height: 20px;
          font-size: 18px;
        }
      }
    }
    li.active{
      >a{
        color: $light-color;
      }
    }
    .nav-lvl-1{
      ul{
        height: 0;
        max-height: 0;
        overflow: hidden;
        maegin-bottom: 30px;
      }
      li{
        &.unfold{
          ul{
            height: auto;
            max-height: none;
          }
        }
      }

    }
  }
}
#burger{
  z-index: 999;
  display: none;
  width: 36px;
  height: 24px;
  margin-left: 20px;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  flex-shrink: 0;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  cursor: hand;
  @include smView() {
    display: block;
  }
  span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    background: $primary-color;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .3s ease-in-out;
    &:nth-child(1) {
      top: 0;
    }
    &:nth-child(2), &:nth-child(3) {
      top: 10px;
    }
    &:nth-child(4) {
      top: 20px;
    }
  }
  &.open{
    span {
      background-color: $light-color;
      &:nth-child(1) {
        top: 10px;
        width: 0%;
        left: 50%;
      }
      &:nth-child(2) {
        transform: rotate(45deg);
      }
      &:nth-child(3) {
        transform: rotate(-45deg);
      }
      &:nth-child(4) {
        top: 10px;
        width: 0%;
        left: 50%;
      }
    }
  }
}


