.has-dots {
  margin-bottom: calc(#{$carousel-dots-margin} + #{$carousel-dots-height});
}

.carousel__dots {
  margin: 0 auto;
  padding: 0;

  position: absolute;
  top: calc(100% + #{$carousel-dots-margin});
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;

  list-style: none;
  user-select: none;

  .carousel__dot {
    margin: 0;
    padding: 0;

    display: block;
    position: relative;

    width: $carousel-dots-width;
    height: $carousel-dots-height;

    cursor: pointer;

    &:after {
      content: "";
      width: $carousel-dots-dot-width;
      height: $carousel-dots-dot-height;
      border-radius: 50%;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      background-color: $carousel-dots-dot-color;

      @if ($carousel-dots-dot-opacity < 1) {
        opacity: $carousel-dots-dot-opacity;
        transition: opacity 0.15s ease-in-out;
      }
    }

    @if ($carousel-dots-dot-opacity < 1) {
      &.is-selected:after {
        opacity: 1;
      }
    }
  }
}
