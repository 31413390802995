

.fancybox__thumbs {
  flex: 0 0 auto;
  position: relative;
  padding: $fancybox-thumbs-padding;

  opacity: var(--fancybox-opacity, 1);

  .fancybox__container.is-animated[aria-hidden="false"] & {
    animation: 0.15s ease-in backwards fancybox-fadeIn;
  }

  .fancybox__container.is-animated.is-closing & {
    opacity: 0;
  }

  & .carousel__slide {
    flex: 0 0 auto;
    width: $fancybox-thumbs-width;

    margin: 0;
    padding: $fancybox-thumbs-spacing;

    box-sizing: content-box;

    display: flex;
    align-items: center;
    justify-content: center;

    overflow: visible;

    cursor: pointer;

    @if ($fancybox-thumbs-selected-border != false) {
      & .fancybox__thumb::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 1px solid $fancybox-accent-color;
        opacity: 0;
        transition: opacity 0.15s ease;

        @if ($fancybox-thumbs-border-radius != false) {
          border-radius: $fancybox-thumbs-border-radius;
        }
      }

      &.is-nav-selected .fancybox__thumb::after {
        opacity: 0.92;
      }
    }

    > * {
      pointer-events: none;
      user-select: none;
    }
  }
}

.fancybox__thumb {
  position: relative;
  width: 100%;

  /* Keep aspect ratio */
  padding-top: calc(100% / (#{$fancybox-thumbs-ratio}));

  background-size: contain;
  background-position: center center;
  background-color: $fancybox-thumbs-bg;
  background-repeat: no-repeat;

  @if ($fancybox-thumbs-border-radius != false) {
    border-radius: $fancybox-thumbs-border-radius;
  }

  @if ($fancybox-thumbs-selected-opacity != false) {
    .is-nav-selected & {
      opacity: $fancybox-thumbs-selected-opacity;
    }
  }
}
