#footer{
  text-transform: uppercase;
  color: $sixtiary-color;
  max-width: 750px;
  margin-left: auto;
  margin-top: 50px;
  margin-bottom: 30px;
  @include lView() {
    ul{
      width: 100%;
      flex-wrap: nowrap;
      display: flex;
      justify-content: space-between;
      li{
        justify-content: space-between;
      }
    }
  }
  @include smView() {
    padding: 0 15px;
    margin-top: 15px;
  }
   a{
     color: $sixtiary-color;
     &:hover{
       color: $tertiary-color;
     }
   }
  .textImage{
    .text{
      @include flexBoxes(space-between, flex-start, row, nowrap);
    }
  }
}
