.fancybox__image {
  transform-origin: 0 0;
  touch-action: none;
  user-select: none;
  transition: none;
}

.has-image {
  .fancybox__content {
    padding: 0;
    background: transparent;
    min-height: 1px;

    .is-closing & {
      overflow: visible;
    }
  }

  &[data-image-fit="contain"] {
    overflow: visible;
    touch-action: none;

    .fancybox__image {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }

  &[data-image-fit="contain-w"] {
    overflow-x: hidden;
    overflow-y: auto;

    .fancybox__content {
      min-height: auto;
    }

    .fancybox__image {
      max-width: 100%;
      height: auto;
    }
  }

  &[data-image-fit="cover"] {
    overflow: visible;
    touch-action: none;

    .fancybox__content {
      width: 100%;
      height: 100%;
    }

    .fancybox__image {
      width: 100%;
      height: 100%;

      object-fit: cover;
    }
  }
}
