.textImage{
  .image{
    img{
      &.full{
        max-width: 100%;
      }
      &.half{
        max-width: 50%;
      }
      &.small{
        max-width: 25%;
      }
      &.tiny{
        max-width: 12.5%;
      }
    }
  }
}
