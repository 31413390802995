.fancybox__toolbar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 20;

  background: $fancybox-toolbar-bg;
  padding: $fancybox-toolbar-padding;

  touch-action: none;

  display: flex;
  justify-content: space-between;

  --carousel-button-svg-width: 20px;
  --carousel-button-svg-height: 20px;

  @include fancybox-breakpoint(toolbar);

  opacity: var(--fancybox-opacity, 1);
  text-shadow: var(--fancybox-toolbar-text-shadow, 1px 1px 1px rgba(0, 0, 0, 0.4));

  .fancybox__container.is-animated[aria-hidden="false"] & {
    animation: 0.15s ease-in backwards fancybox-fadeIn;
  }

  .fancybox__container.is-animated.is-closing & {
    opacity: 0;
  }

  &__items {
    display: flex;

    &--left {
      margin-right: auto;
    }

    &--center {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    &--right {
      margin-left: auto;
    }

    @media (max-width: 640px) {
      &--center:not(:last-child) {
        display: none;
      }
    }
  }
}

.fancybox__counter {
  min-width: 72px;
  padding: 0 10px;
  line-height: var(--carousel-button-height, 48px);
  text-align: center;
  font-size: 17px;
  font-variant-numeric: tabular-nums;
  -webkit-font-smoothing: subpixel-antialiased;
}

.fancybox__progress {
  background: $fancybox-accent-color;
  height: 3px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: scaleX(0);
  transform-origin: 0;
  transition-property: transform;
  transition-timing-function: linear;
  z-index: 30;
  user-select: none;
}

.fancybox__container:fullscreen::backdrop {
  opacity: 0;
}

.fancybox__button--fullscreen g:nth-child(2) {
  display: none;
}

.fancybox__container:fullscreen .fancybox__button--fullscreen g:nth-child(1) {
  display: none;
}

.fancybox__container:fullscreen .fancybox__button--fullscreen g:nth-child(2) {
  display: block;
}

.fancybox__button--slideshow g:nth-child(2) {
  display: none;
}

.fancybox__container.has-slideshow .fancybox__button--slideshow g:nth-child(1) {
  display: none;
}

.fancybox__container.has-slideshow .fancybox__button--slideshow g:nth-child(2) {
  display: block;
}
