$carousel-prefix: "" !default;
$carousel-slide-width: var(--carousel-slide-width, 60%) !default;

/*
    Dots plugin
*/
$carousel-dots-margin: 0.5rem !default;
$carousel-dots-width: 22px !default;
$carousel-dots-height: 22px !default;

$carousel-dots-dot-width: 8px !default;
$carousel-dots-dot-height: 8px !default;
$carousel-dots-dot-color: currentColor !default;
$carousel-dots-dot-opacity: 0.25 !default;

/*
    Navigation plugin
*/

$carousel-button-width: var(--carousel-button-width, 48px) !default;
$carousel-button-height: var(--carousel-button-height, 48px) !default;

$carousel-button-color: var(--carousel-button-color, currentColor) !default;
$carousel-button-bg: var(--carousel-button-bg, transparent) !default;
$carousel-button-shadow: var(--carousel-button-shadow, none) !default;
$carousel-button-border-radius: var(--carousel-button-border-radius, 50%) !default;

$carousel-button-prev-pos: 10px !default;
$carousel-button-next-pos: 10px !default;

$carousel-button-disabled-opacity: 0.3 !default;

$carousel-button-svg-width: var(--carousel-button-svg-width, 50%) !default;
$carousel-button-svg-height: var(--carousel-button-svg-height, 50%) !default;
$carousel-button-svg-stroke-width: var(--carousel-button-svg-stroke-width, 1.5) !default;
$carousel-button-svg-filter: var(--carousel-button-svg-filter, none) !default;
$carousel-button-svg-fill: none !default;
$carousel-button-svg-scale: false !default;
