body{
  font-family: Arial, Helvetica, Geneva, SunSans-Regular, sans-serif;
  font-size: 14px;
  font-weight: normal;
  text-decoration: none;
  color: $light-color;
  background: $dark-color no-repeat top left url('../img/bg.jpg');
  @include smView() {
    background: none;
    background-color: $quartiary-color;
    font-size: 16px;
    line-height: 22px;
    max-width: 100vw;
    overflow-x: hidden;
    min-height: 100vh;
  }
  &.nav-open{
    overflow: hidden;
  }
}
.hide-medium-up {
  @include mlView() {
    display: none;
  }
}

.hide-medium-down {
  @include smView() {
    display: none;
  }
}

.show-medium-down {
  display: none;
  @include smView() {
    display: block;
  }
}

.hide-small {
  @include sView() {
    display: none;
  }
}

.show-small {
  display: none;
  @include sView() {
    display: block;
  }
}

/* scrollbar styling */
/* Firefox */
html {
  scrollbar-color: $quartiary-color $quintiary-color;
  scrollbar-width: thin;
}

strong{
  font-weight: bold;
}

/* WebKit and Chromiums */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: $quartiary-color;
}

::-webkit-scrollbar-thumb {
  background: $quintiary-color;
  border-radius: 5px;
}

#all-wrapper{
  margin: 0;
  padding: 0;
  //width: 970px;
  max-width: 970px;
  text-align: left;
  @include smView() {
    max-width: 100vw;
    overflow-x: hidden;
    min-height: 100vh;
  }
}

a{
  text-decoration: none;
  color: $primary-color;
  &:hover{
    color: $tertiary-color;
  }
}
h1, h3 {
  margin: 0 0 20px 0;
  padding: 0;
  font-size: 24px;
  font-weight: normal;
  color: #fff;
  font-family: 'TradeGothicBoldCondTwenty',Arial, Helvetica, Geneva, SunSans-Regular, sans-serif;
  line-height: 100%;
}

h3{
  margin-bottom: 10px;
}


h2{
  margin: 0 0 15px 24px;
  padding: 0;
  font-size: 100%;
  font-weight: normal;
  text-transform: uppercase;
  @include smView() {
    margin: 0 0 15px 0px;
  }
}

#main-wrapper{
  position: relative;
  z-index: 1;
  @include lView() {
    @include flexBoxes(flex-end, flex-start, row, nowrap);
  }
  @include smView() {
    padding: 0 15px;
  }
  #content{
    flex: 1;
  }
}


#content{
  opacity: 1;
  -webkit-transition: opacity 200ms ease-in-out;
  -moz-transition: opacity 200ms ease-in-out;
  transition: opacity 200ms ease-in-out;
  &.dimmed{
    opacity: 0.5;
  }
  em{
    font-style:italic;
  }
  .gallery{
    margin: 0 0 60px 0;
    @include smView() {
      margin: 0 0 30px 0;
    }
  }
  .textImage{
    margin: 0 0 20px 0;
    @include smView() {
      margin: 0 0 10px 0;
    }
  }
}

#colmain{
  min-height: 500px;
  @include mlView() {
    min-height: auto;
  }
}

