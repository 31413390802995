.fancybox__backdrop,
.fancybox__caption,
.fancybox__nav,
.carousel__dots,
.carousel__button.is-close {
  opacity: var(--fancybox-opacity, 1);
}

/* Opening */
.fancybox__container.is-animated[aria-hidden="false"] {
  & .fancybox__backdrop,
  & .fancybox__caption,
  & .fancybox__nav,
  & .carousel__dots,
  & .carousel__button.is-close {
    animation: 0.15s ease backwards fancybox-fadeIn;
  }
}

/* Closing */
.fancybox__container.is-animated.is-closing {
  & .fancybox__backdrop,
  & .fancybox__caption,
  & .fancybox__nav,
  & .carousel__dots,
  & .carousel__button.is-close {
    animation: 0.15s ease both fancybox-fadeOut;
  }
}

.fancybox-fadeIn {
  animation: 0.15s ease both fancybox-fadeIn;
}

.fancybox-fadeOut {
  animation: 0.1s ease both fancybox-fadeOut;
}

.fancybox-zoomInUp {
  animation: 0.2s ease both fancybox-zoomInUp;
}

.fancybox-zoomOutDown {
  animation: 0.15s ease both fancybox-zoomOutDown;
}

/* Both `throwOut` classes are for closing animation after dragging up/down */
.fancybox-throwOutUp {
  animation: 0.15s ease both fancybox-throwOutUp;
}

.fancybox-throwOutDown {
  animation: 0.15s ease both fancybox-throwOutDown;
}

@keyframes fancybox-fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fancybox-fadeOut {
  to {
    opacity: 0;
  }
}

@keyframes fancybox-zoomInUp {
  from {
    transform: scale(0.97) translate3d(0, 16px, 0);
    opacity: 0;
  }

  to {
    transform: scale(1) translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fancybox-zoomOutDown {
  to {
    transform: scale(0.97) translate3d(0, 16px, 0);
    opacity: 0;
  }
}

@keyframes fancybox-throwOutUp {
  to {
    transform: translate3d(0, -30%, 0);
    opacity: 0;
  }
}

@keyframes fancybox-throwOutDown {
  to {
    transform: translate3d(0, 30%, 0);
    opacity: 0;
  }
}
