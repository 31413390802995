.gallery{
  .inner{
    @include smView() {
      text-align: left;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      &:after {
        content: "";
        width: 31%;
      }
    }


    a{
      display: inline-block;
      margin: 0 10px 10px 0;
      @include smView() {
        width: 29%;
      }

      &:focus{
        border: none;
        outline: none;
      }

      img{
        display: inline-block;
        max-height: 80px;
        @include smView() {
          max-height: none;
          display: inline-block;
          width: 100%;
          height: auto;
          margin: 0 0 0 0;
          padding: 0 0 0 0;
        }
        &:hover{
          background-color: white;
          opacity: 0.8;
          cursor: pointer;
          cursor: hand;
        }
      }
    }
  }
  .enlarge-hint{
    clear: both;
    small{
      color: #8D8D8D;
      font-size: 10px;
    }
  }
  .text{
    margin: 20px 0;
  }
}

.gallery-image{
  //max-width: 120px;
}

.fancybox__nav{
  .carousel__button.is-prev, .carousel__button.is-next {
      top: calc(50vh + 0px);
  }
}

.fancybox__container {
  --fancybox-thumbs-border-radius: 0%;
  --fancybox-thumbs-ratio: 1;

  --fancybox-accent-color: #000000;
}


