#header{
  max-width: 970px;
  height: 105px;
  overflow: hidden;
  position: relative;
  @include smView() {
    overflow: visible;
    height: 50px;
  }
  #logo {
    z-index: 1101;
    position: absolute;
    top: 45px;
    left: 24px;
    width: 146px;
    @include smView() {
      top: 15px;
      left: 15px;
      width: 170px;
    }
  }
}
