.fancybox__carousel {
  .fancybox__slide {
    $p: &;

    @at-root .fancybox__content {
      #{$p}.has-iframe &,
      #{$p}.has-map &,
      #{$p}.has-pdf &,
      #{$p}.has-video &,
      #{$p}.has-html5video & {
        flex-shrink: 1;
        min-height: 1px;
        overflow: visible;
      }

      #{$p}.has-iframe &,
      #{$p}.has-map &,
      #{$p}.has-pdf & {
        width: $fancybox-iframe-width;
        height: $fancybox-iframe-height;
      }

      #{$p}.has-video &,
      #{$p}.has-html5video & {
        width: $fancybox-video-width;
        height: $fancybox-video-height;

        max-width: 100%;
        max-height: 100%;
      }

      #{$p}.has-map &,
      #{$p}.has-pdf &,
      #{$p}.has-video &,
      #{$p}.has-html5video & {
        padding: 0;
        background: rgba(24, 24, 27, 0.9);
        color: #fff;
      }

      #{$p}.has-map & {
        background: #e5e3df;
      }
    }
  }
}

.fancybox__html5video,
.fancybox__iframe {
  border: 0;
  display: block;
  height: 100%;
  width: 100%;
  background: transparent;
}

.fancybox-placeholder {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
