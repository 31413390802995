.carousel__button {
  width: $carousel-button-width;
  height: $carousel-button-height;

  padding: 0;
  border: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  pointer-events: all;
  cursor: pointer;
  color: #979797;
  background: $carousel-button-bg;

  @if ($carousel-button-border-radius != false) {
    border-radius: $carousel-button-border-radius;
  }

  @if ($carousel-button-shadow != false) {
    box-shadow: $carousel-button-shadow;
  }

  &.is-prev,
  &.is-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &.is-prev {
    left: $carousel-button-prev-pos;
  }

  &.is-next {
    right: $carousel-button-next-pos;
  }

  @if ($carousel-button-disabled-opacity != false) {
    transition: opacity 0.15s ease;

    &[disabled] {
      cursor: default;
      opacity: $carousel-button-disabled-opacity;
    }
  }

  & svg {
    width: $carousel-button-svg-width;
    height: $carousel-button-svg-height;

    fill: $carousel-button-svg-fill;

    stroke: currentColor;
    stroke-width: $carousel-button-svg-stroke-width;
    stroke-linejoin: bevel;
    stroke-linecap: round;

    filter: $carousel-button-svg-filter;

    pointer-events: none;

    @if ($carousel-button-svg-scale != false) {
      transform: scale($carousel-button-svg-scale);
    }
  }
}
