.fancybox__spinner {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);

  width: $fancybox-spinner-width;
  height: $fancybox-spinner-height;

  color: $fancybox-spinner-color;

  @include fancybox-breakpoint(loader);

  .fancybox__slide & {
    cursor: pointer;
    z-index: 1053;
  }

  & svg {
    animation: fancybox-rotate 2s linear infinite;
    transform-origin: center center;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    margin: auto;

    width: 100%;
    height: 100%;

    & circle {
      fill: none;
      stroke-width: $fancybox-spinner-svg-stroke-width;
      stroke-miterlimit: 10;

      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      animation: fancybox-dash 1.5s ease-in-out infinite;
      stroke-linecap: round;
      stroke: currentColor;
    }
  }
}

@keyframes fancybox-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fancybox-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
